<template>
  <BaseDialog ref="baseDialog" width="600px" :dialogVisible.sync="dialog" :title="title">
    <template>
      <BaseForm v-loading="loading" ref="form" label-width="130px" :cols="formField" :form="form" :formVisible="dialog">
        <template #logo="{ scoped: { prop } }">
          <CacheImg
            v-if="fileList && fileList[0]"
            ref="img"
            style="width: 80px; height: 80px"
            :raw="fileList[0].raw"
            fit="contain"
            @click="to"
          />
          <el-button v-else type="primary" size="mini" class="filter-item" @click="to"> 选择文件 </el-button>

          <PicUploadDialog
            title="选择文件"
            accept="image/jpeg,image/png,image/jpg"
            :dialogVisible.sync="uploadVisible"
            @picsReadyHandler="picsReadyHandler"
          />
        </template>
        <template #isDockApiSlot="{ scoped: { prop } }">
          <AvueCrudRadio v-model="form['isDockApi']" :dic="isDockApiDic"></AvueCrudRadio>
        </template>
        <template #isImportWaybillSlot="{ scoped: { prop } }">
          <AvueCrudRadio v-model="form['isImportWaybill']" :dic="isImportWaybillDic"></AvueCrudRadio>
        </template>
      </BaseForm>
    </template>
    <template #footer>
      <LoadingBtn size="mini" @click="cancel"> 取消 </LoadingBtn>
      <LoadingBtn size="mini" type="primary" @click="doSubmit"> 保存 </LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import formField from './field'
import { commonFromMixin } from '@/mixins'
import { deepClone } from '@/components/avue/utils/util'
import { mapGetters } from 'vuex'
import { getPicName } from '@/utils'
import PicUploadDialog from '@/components/picUploadDialog'
import CacheImg from '@/components/cacheImg'
import { createPlatform as add } from '@/api/shop/manageApi'

export default {
  mixins: [commonFromMixin],
  components: { PicUploadDialog, CacheImg },
  props: {
    initData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fileList: null,
      uploadVisible: false,
      formField: deepClone(formField),
      form: {},
      loading: false,
      isDockApiDic: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ],
      isImportWaybillDic: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ]
    }
  },
  computed: {},
  watch: {},
  methods: {
    async doSubmit() {
      try {
        await this.$refs['form'].validate()
      } catch {
        return false
      }

      // 图片存在
      if (this.fileList && this.fileList[0]) {
        await $uploadOSSPics([
          {
            files: [this.fileList[0].raw],
            dirPrefix: $ossDirMapWithType['0'],
            prop: 'path',
            sucCallBack: (res) => {
              console.log('sucCallBack', res)
              this.logo = res
              this.checkAdd()
            },
            errCallBack: (res) => {
              console.log('errCallBack', res)
              this.$message.warning('LOGO上传失败')
            }
          }
        ])
      } else {
        this.checkAdd()
      }
    },
    async checkAdd() {
      let params = deepClone(Object.assign(this.form, this.initData))
      params.logo = this.logo
      this.loading = true
      const [err, res] = await awaitWrap(add(params))
      if ($SUC(res)) {
        this.success()
        this.sup_this && this.sup_this.init()
      }
      this.loading = false
    },
    to() {
      this.uploadVisible = true
    },
    picsReadyHandler(fileList) {
      console.log('fileList', fileList)
      this.fileList = fileList
        .map((item) => {
          if (['jpg', 'jpeg', 'png'].includes(item.name.split('.')[1])) {
            this.$set(item, 'isShare', true)
            this.$set(item, 'title', getPicName(item.name || ''))
            return item
          }
        })
        .filter(Boolean)
      if (!this.fileList.length) {
        this.$nextTick(function () {
          this.$message.error('请上传jpg、jpeg或者png格式的图片（请确保后缀是小写）')
        })
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio {
  padding-top: 2px;
}
</style>
